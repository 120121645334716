export function header() {
	const headerEl = document.querySelector('.site-header')

	function updateHeaderClass() {
		const scrollPosition = window.scrollY
		if (scrollPosition > 0) {
			headerEl.classList.replace('site-header--top', 'site-header--not-top')
		} else {
			headerEl.classList.replace('site-header--not-top', 'site-header--top')
		}
	}

	if (document.readyState === 'interactive') updateHeaderClass()

	window.addEventListener('scroll', updateHeaderClass)
}
